import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import API from "../../Network/API";

export default function TutorTransaction(){
       const dispatch = useDispatch();
           const [data,setData]=useState()
      
            useEffect(() => {
                
                API.get(`/v1/oauth/teacher/transactions`)
                .then((response) => {
                    setData(response.data.data)
                }).catch((error) => {
                });
        
            }, []);

    return (
        <>
        <div className="dashboard-title">
                <h4>Transaction History</h4>
            </div>
            <div className="row">
            <div className="col-12">
            <div className="table-responsive custom-table">
                                <table className="table table-nowrap mb-0">
                                    <thead>
                                        <tr className="fw-bold">
                                            <th>Date</th>
                                            <th>Type</th>
                                            <th>Note</th>
                                            <th>Amount</th>
                                            <th>Fee</th>
                                            <th>Total</th>
                                           
                                        </tr>
                                    </thead>
                                    <tbody>

                                    {!data?.loading && data?.courses?.length > 0 && data?.courses?.map((course, j) => (
                                        <tr>
                                            <td>{course.date}</td>
                                            <td>{course.type}</td>
                                            <td>{course.note}</td>
                                            <td>{course.amount}</td>
                                            <td>{course.fee}</td>
                                            <td>{course.total}</td>
                                            
                                        </tr>
                                     ))}

                                    </tbody>
                                    </table>
                                    </div>
                </div>
                </div>
        </>
    )
}