import { useDispatch, useSelector } from "react-redux";
import { USER_LOGGED_IN } from "./Reducer/authSlice";
import { BrowserRouter, Routes, Route, useLocation, Outlet, Navigate, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Home from "./Pages/Home";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Login from "./Auth/Login";
import SignUp from "./Auth/Signup";
import SignupStudent from "./Auth/SignupStudent";
import TutorDashboard from "./Pages/Tutor/TutorDashboard";
import StudentDashboard from "./Pages/Student/StudentDashboard";
import AllCourse from "./Pages/AllCourse";
import CourseDetail from "./Pages/CourseDetail";
import BecomeTeach from "./Pages/BecomeTeach";
import TutorHome from "./Pages/Tutor/TutorHome";
import StudentHome from "./Pages/Student/StudentHome";
import SinglePage from "./Pages/SinglePage";
import CategoryCourse from "./Pages/CategoryCourse";
import Checkout from "./Pages/Checkout";
import OrderComplete from "./Pages/OrderComplete";
import MyLesson from "./Pages/Student/MyLesson";
import LessonView from "./Pages/Student/LessonView";
import LessonViewLive from "./Pages/Student/LessonViewLive";
import TutorCourse from "./Pages/Tutor/Course/TutorCourse";
import AddCourse from "./Pages/Tutor/Course/AddCourse";
import EditCourse from "./Pages/Tutor/Course/EditCourse";
import CourseSection from "./Pages/Tutor/Section/CourseSection";
import RoomView from "./Pages/RoomView";
import FavCourse from "./Pages/Student/FavCourse";
import StudentChat from "./Pages/Student/StudentChat";
import ChatBody from "./Props/ChatBody";
import BlankInbox from "./Props/BlankInbox";
import TeacherChat from "./Pages/Tutor/TeacherChat";
import TeacherList from "./Pages/TeacherList";
import TeacherView from "./Pages/TeacherView";
import Setting from "./Pages/Settings/Setting";
import LanguageSetting from "./Pages/Settings/LanguageSetting";
import ScheduleSetting from "./Pages/Settings/ScheduleSetting";
import PasswordChange from "./Pages/Settings/PasswordChange";
import ProfileSetting from "./Pages/Student/Settings/ProfileSetting";
import StudentPasswordChange from "./Pages/Student/Settings/StudentPasswordChange";
import AppHome from "./MobileApp/AppHome";
import MobileNav from "./MobileApp/MobileNav";
import API from "./Network/API";
import { useTranslation } from "react-i18next";
import { MENU_FETCH_SUCCESS } from "./Reducer/menuSlice";
import { APPEARANCE_FETCH_ERROR, APPEARANCE_FETCH_SUCCESS } from "./Reducer/appearanceSlice";
import { CATEGORY_FETCH_SUCCESS } from "./Reducer/categorySlice";
import { COUNTRY_FETCH_SUCCESS } from "./Reducer/countrySlice";
import { LANGUAGE_FETCH_SUCCESS } from "./Reducer/languageSlice";
import { HOME_FETCH_COURSE_SUCCESS, HOME_FETCH_START } from "./Reducer/homeSlice";
import { COURSE_FETCH_START, COURSE_FETCH_SUCCESS, COURSE_TOTAL_PAGE } from "./Reducer/courseSlice";
import { TEACHER_FETCH_START, TEACHER_FETCH_SUCCESS, TEACHER_TOTAL_PAGE } from "./Reducer/teacherSlice";
import AppLogin from "./Auth/AppLogin";
import AppMain from "./MobileApp/AppMain";
import AllEnrollmentCourse from "./Pages/Tutor/AllEnrollmentCourse";
import Subscription from "./Pages/Tutor/RoomRent/Subscription";
import Terms from "./Pages/Terms";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import ForgotPassword from "./Auth/ForgotPassword";
import ResetPassword from "./Auth/ResetPassword";
import TutorTransaction from "./Pages/Tutor/TutorTransaction";
import TutorEarning from "./Pages/Tutor/TutorEarning";
import EnrollmentCourseDetails from "./Pages/Tutor/EnrollmentCourseDetails";
import CourseView from "./Pages/Tutor/Course/CourseView";
import WithdrawIndex from "./Pages/Tutor/withdraw/WithdrawIndex";
import BankInfoEdit from "./Pages/Settings/BankInfoEdit";
import Help from "./Pages/Help";
import TutorHelp from "./Pages/Tutor/TutorHelp";
import StudentTransaction from "./Pages/Student/StudentTransaction";

function App() {
  const location = useLocation();
  const isAppRoute = location.pathname.startsWith("/app");
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const { user, accessToken } = useSelector((state) => state.authReducer);
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("xller_auth"));
    if (userInfo) {
      dispatch(USER_LOGGED_IN(userInfo));
    }

  }, [dispatch]);

  useEffect(() => {

    API.get(`/menus`).then((response) => {
      dispatch(MENU_FETCH_SUCCESS(response.data.data))
    }).catch((error) => {
    });

    API.get('/appearance').then((response) => {
      dispatch(APPEARANCE_FETCH_SUCCESS(response.data.data));
    }).catch((error) => {
      dispatch(APPEARANCE_FETCH_ERROR());
    });

    API.get(`/v1/oauth/categories`).then((response) => {
      dispatch(CATEGORY_FETCH_SUCCESS(response.data.data.categories))
    }).catch((error) => {
    });

    API.get(`/v1/oauth/countries`).then((response) => {
      dispatch(COUNTRY_FETCH_SUCCESS(response.data.data.countries))
    }).catch((error) => {
    });

    API.get(`/v1/oauth/language`).then((response) => {
      dispatch(LANGUAGE_FETCH_SUCCESS(response.data.data.languages))
    }).catch((error) => {
    });


    API.get(`/v1/oauth/check_user`).then((response) => {

      localStorage.setItem(
        'xller_auth',
        JSON.stringify({
          user: response?.data?.user,
          accessToken: localStorage.getItem('xller_token'),
        })
      )

      const userInfo = JSON.parse(localStorage.getItem("xller_auth"));
      if (userInfo) {
        dispatch(USER_LOGGED_IN(userInfo));
      }

    }).catch((error) => {
      // console.log(error?.response?.status);
      if (error?.response?.status == 401 && error?.response?.statusText == "Unauthorized") {
        // navigator('/login')
        // localStorage.clear();
      }
    });

    dispatch(HOME_FETCH_START())
    dispatch(COURSE_FETCH_START())
    dispatch(TEACHER_FETCH_START())

    API.get(`/v1/oauth/all_course`).then((response) => {
      dispatch(COURSE_FETCH_SUCCESS(response.data.data))
      dispatch(COURSE_TOTAL_PAGE(response.data.data.total_page))
      dispatch((HOME_FETCH_COURSE_SUCCESS(response.data.data.courses)))
    }).catch((error) => {
    });

    API.get(`/v1/oauth/all_teachers`).then((response) => {
      dispatch(TEACHER_FETCH_SUCCESS(response.data.data))
      dispatch(TEACHER_TOTAL_PAGE(response.data.data.total_page))
    }).catch((error) => {
    });


  }, []);
  
  return (
    <>
       {!isAppRoute && <Header />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/signup-student" element={<SignupStudent />} />
        <Route path="/signup-tutor" element={<SignUp />} />
        <Route path="/all-courses" element={<AllCourse />} />
        <Route path="/course/:slug" element={<CourseDetail />} />
        <Route path="/page/:slug" element={<SinglePage />} />
        <Route path="/category/:slug" element={<CategoryCourse />} />
        <Route path="/teach" element={<BecomeTeach />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/order/process" element={<OrderComplete />} />
        <Route path="/teachers" element={<TeacherList />} />
        <Route path="/teacher/:id" element={<TeacherView />} />

        <Route path="/dashboard" element={<TutorDashboard />}>

          <Route path="tutor" element={<TutorHome />} />
          <Route path="subscription" element={<Subscription />} />
          <Route path="all-enrollment-courses" element={<AllEnrollmentCourse />} />
          <Route path="enrollment-details/:id" element={<EnrollmentCourseDetails />} />
          <Route path="settings" element={<Setting />} />
          <Route path="transactions" element={<TutorTransaction />} />
          <Route path="earnings" element={<TutorEarning />} />
          <Route path="withdraw" element={<WithdrawIndex />} />
          <Route path="help" element={<TutorHelp />} />
          <Route path="settings/languages" element={<LanguageSetting />} />
          <Route path="settings/schedules" element={<ScheduleSetting />} />
          <Route path="settings/change-bank-info" element={<BankInfoEdit />} />
          <Route path="my-courses" element={<TutorCourse />} />
          <Route path="my-course/create" element={<AddCourse />} />
          <Route path="my-course/edit/:id" element={<EditCourse />} />
          <Route path="my-course/view/:id" element={<CourseView />} />
          <Route path="my-course/sections/:id" element={<CourseSection />} />
          <Route path="settings/change-password" element={<PasswordChange />} />
          <Route path="chat" element={<TeacherChat />}>
            <Route index element={<BlankInbox />} />
            <Route path="conversation/:id" element={<ChatBody />} />
          </Route>

        </Route>

        <Route path="/home" element={<StudentDashboard />} >
          <Route path="student" element={<StudentHome />} />
          <Route path="settings/profile" element={<ProfileSetting />} />
          <Route path="settings/change-password" element={<StudentPasswordChange />} />
          <Route path="my-lessons" element={<MyLesson />} />
          <Route path="save-course" element={<FavCourse />} />
          <Route path="chat" element={<StudentChat />}>
            <Route index element={<BlankInbox />} />
            <Route path="conversation/:id" element={<ChatBody />} />
          </Route>

          <Route path="transactions" element={<StudentTransaction />} />

        </Route>
        <Route path="lesson/:course_slug/:lesson_id" element={<LessonView />} />
        <Route path="room/:course_slug/:room_id" element={<LessonViewLive />} />
        <Route path="terms" element={<Terms />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />


{/* // this is for mobile route */}
        <Route path="/app" element={<AppMain />}>

          <Route index element={<AppHome />} />
          <Route path="login" element={<AppLogin />} />
          <Route path="all-courses" element={<AllCourse />} />
          <Route path="course/:slug" element={<CourseDetail />} />
          <Route path="page/:slug" element={<SinglePage />} />
          <Route path="category/:slug" element={<CategoryCourse />} />
          <Route path="teach" element={<BecomeTeach />} />
          <Route path="checkout" element={<Checkout />} />
          <Route path="order/process" element={<OrderComplete />} />
          <Route path="teachers" element={<TeacherList />} />
          <Route path="teacher/:id" element={<TeacherView />} />

          <Route path="dashboard" element={<TutorDashboard />}>

          <Route path="tutor" element={<TutorHome />} />
          <Route path="settings" element={<Setting />} />
          <Route path="settings/languages" element={<LanguageSetting />} />
          <Route path="settings/schedules" element={<ScheduleSetting />} />
          <Route path="my-courses" element={<TutorCourse />} />
          <Route path="my-course/create" element={<AddCourse />} />
          <Route path="my-course/edit/:id" element={<EditCourse />} />
          <Route path="my-course/sections/:id" element={<CourseSection />} />
          <Route path="settings/change-password" element={<PasswordChange />} />
          <Route path="chat" element={<TeacherChat />}>
            <Route index element={<BlankInbox />} />
            <Route path="conversation/:id" element={<ChatBody />} />
          </Route>

        </Route>

        <Route path="home" element={<StudentDashboard />} >
          <Route path="student" element={<StudentHome />} />
          <Route path="settings/profile" element={<ProfileSetting />} />
          <Route path="settings/change-password" element={<StudentPasswordChange />} />
          <Route path="my-lessons" element={<MyLesson />} />
          <Route path="save-course" element={<FavCourse />} />
          <Route path="chat" element={<StudentChat />}>
            <Route index element={<BlankInbox />} />
            <Route path="conversation/:id" element={<ChatBody />} />
          </Route>

        </Route>
        <Route path="lesson/:course_slug/:lesson_id" element={<LessonView />} />
        <Route path="room/:course_slug/:room_id" element={<LessonViewLive />} />

        </Route>

      </Routes>

      {!isAppRoute && <Footer />}

      {isAppRoute && <MobileNav />}

    </>
  );
}

const PrivateRoute = ({ url }) => {

  const selector_permission = useSelector((state) => state.permissionReducer)
  const auth = selector_permission?.main_data?.includes(url) ? true : false; // determine if authorized, from context or however you're doing it

  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  return auth ? <Outlet /> : <Navigate to="/" />;
}
export default App;
