import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { USER_LOGGED_IN } from "../../Reducer/authSlice";
import { useEffect, useState } from "react";
import API from "../../Network/API";
import { MY_COURSE_FETCH_START, MY_COURSE_FETCH_SUCCESS, MY_COURSE_TOTAL_PAGE } from "../../Reducer/myCourseSlice";
import { CHAT_LIST_FETCH_SUCCESS } from "../../Reducer/chatListSlice";
import { UPCOMING_ENROLLMENT_FETCH_START, UPCOMING_ENROLLMENT_FETCH_SUCCESS } from "../../Reducer/upcomingEnrollmentSlice";
import { TEACHER_ENROLLMENT_FETCH_START, TEACHER_ENROLLMENT_FETCH_SUCCESS } from "../../Reducer/tutorEnrollmentSlice";

export default function TutorNav() {
    const { user, accessToken } = useSelector((state) => state.authReducer);
    const [isLoading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const _logout = () => {
        setLoading(false);
        API.post(`/v1/oauth/logout`)
            .then(res => {
                setLoading(false);
                if (res.data.success === true) {
                    localStorage.clear();
                }
            })
        localStorage.clear();
        window.location.href = '/';
    }

    useEffect(() => {


        API.get(`/v1/oauth/chatlist`).then((response) => {
            dispatch(CHAT_LIST_FETCH_SUCCESS(response.data.data))
        }).catch((error) => {
        });

        UPCOMING_ENROLLMENT_FETCH_START();
        API.get(`/v1/oauth/teacher/upcoming_course`).then((response) => {
            dispatch(UPCOMING_ENROLLMENT_FETCH_SUCCESS(response.data.data))
        }).catch((error) => {
        });

        TEACHER_ENROLLMENT_FETCH_START();
        API.get(`/v1/oauth/teacher/enrollment_courses`).then((response) => {
            dispatch(TEACHER_ENROLLMENT_FETCH_SUCCESS(response.data.data))
        }).catch((error) => {
        });

    }, []);

    return (
        <>
            <div className="stickyPanelSidebar">
                <div className="settings-widget dash-profile">
                    <div className="settings-menu">
                        <div className="profile-bg">
                            <div className="profile-img">
                                <a href="#"><img src={user?.avatar} alt={user?.name} /></a>
                            </div>
                        </div>
                        <div className="profile-group">
                            <div className="profile-name text-center">
                                <h4><a href="#">{user?.name}</a></h4>
                                <p>{user?.role == 'teacher' ? "Teacher" : "Student"}</p>
                                <p className="w-100 pt-2">
                                    <b className="border border-success p-1 text-success rounded">${user?.balance ?? 0}</b>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="settings-widget account-settings">
                    <div className="settings-menu">
                        <h3>Dashboard</h3>
                        <ul>
                            <li className="nav-item active">
                                <Link to={`/dashboard/tutor`} className="nav-link">
                                    <i className="fa-light fa-grid-2" />Dashboard
                                </Link>
                            </li>
                            <li className="nav-item ">
                                <Link to={`/dashboard/chat`} className="nav-link">
                                    <i className="fa-light fa-message-lines" />Messages
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={`/dashboard/my-courses`} className="nav-link">
                                    <i className="fa-light fa-chalkboard-user" />Courses
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={`/dashboard/all-enrollment-courses`} className="nav-link">
                                    <i className="fa-light fa-list" />Order History
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={`/dashboard/earnings`} className="nav-link">
                                    <i className="fa-light fa-wallet" />Earning History
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={`/dashboard/transactions`} className="nav-link">
                                    <i className="fa-light fa-chart-bar" />Transaction History
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={`/dashboard/withdraw`} className="nav-link">
                                    <i className="fa-light fa-wallet" />Withdraw History
                                </Link>
                            </li>
                        </ul>
                        <h3>Account Settings</h3>
                        <ul>
                            <li className="nav-item">
                                <Link to="/dashboard/settings" className="nav-link ">
                                    <i className="fa-light fa-user-gear" />Settings
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={`/dashboard/subscription`} className="nav-link ">
                                    <i className="fa-light fa-circle-info" />Subscription
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={`/dashboard/help`} className="nav-link ">
                                    <i className="fa-light fa-circle-info" />Help
                                </Link>
                            </li>
                            <li className="nav-item">
                                <a href="#" className="nav-link" onClick={() => _logout()}>
                                    <i className="fa-light fa-power-off" />Logout{isLoading ? (
                                        <span
                                            className="spinner-border spinner-border-sm ml-5"
                                            role="status"
                                            aria-hidden="true"
                                        ></span>
                                    ) : (
                                        <span></span>
                                    )}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            {/* <div className="mobile-menu">
                <nav className="navbar fixed-top bg-white">
                    <div className="container">
                        <a className="navbar-brand mainHarderLogo" href="#">
                            <img src="assets/img/logo.svg" alt="" />
                        </a>
                        <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon" />
                        </button>
                        <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                            <div className="offcanvas-header">
                                <a className="navbar-brand mobile-offcanvs-logo d-flex" href="#">
                                    <div className="schdhelTutorImg me-2">
                                        <img className="img-fluid" src="assets/img/Teacherimages.jpg" alt="" />
                                    </div>
                                    <h2 className="fs-20-600 mb-0">Book a trial lesson</h2>
                                </a>
                                <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" />
                            </div>
                            <div className="offcanvas-body">
                                <ul className="navbar-nav justify-content-end flex-grow-1 px-3 mobile-offcanvs-menu">
                                    <li className="nav-item">
                                        <a className="nav-link" href="#">Dashboard</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#">Messages</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#">My lessons</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#">Saved tutors</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#">Settings</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#">Help</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#">Sign Out</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>
            </div> */}
        </>
    )
}