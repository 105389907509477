import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import API from "../../../Network/API";
import { useNavigate, useParams } from "react-router-dom";
import { EDIT_MY_COURSE_SUCCESS, FIND_MY_COURSE_FETCH_SUCCESS } from "../../../Reducer/myCourseSlice";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

export default function EditCourse(props) {
    const categories = useSelector((state) => state.categoryReducer);
    const select_course = useSelector((state) => state.myCourseReducer);
    const [show, setShow] = useState(false);
    const [step, setStep] = useState(0);
    const [loading, setLoad] = useState(false);
    const [file, setFile] = useState(null);
    const [video_file, setVideoFile] = useState(null);
    const [data, setData] = useState();
    const [course_type, setCourseType] = useState(0);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [photoURL, setPhotoURL] = useState(null);
    const [videoURL, setVideoURL] = useState(null);
    const editorRef = useRef(null);
    const editorRef1 = useRef(null);
    const [order_setting, setOrderSetting] = useState();
    const [total_fee, setTotalFee] = useState(0);
    const [total_price, setTotalPrice] = useState(0);

    const [selectedFile, setSelectedFiles] = useState(null);
    const [cropper, setCropper] = useState(null);
    const [uploadStatus, setUploadStatus] = useState("");

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => setSelectedFiles(reader.result);
            reader.readAsDataURL(file);
        }
    }


    const prams = useParams();
    const handleShow = () => {
        setShow(!show)
    };


    const courseType = (type) => {
        setCourseType(type)
    }


    useEffect(() => {

        API.get(`/v1/oauth/order_setting`).then((response) => {
            setOrderSetting(response.data.data)
            setTotalFee(data?.course_price * response.data.data?.order_fee / 100);
            setTotalPrice(data?.course_price - data?.course_price * response.data.data?.order_fee / 100);
        }).catch((error) => {
        });

        setData(select_course?.courses?.find(course => course.id == prams.id))
        // console.log(select_course?.courses?.find(course => course.id == prams.id));
        // console.log(order_setting)
        setTotalFee(data?.course_price * order_setting?.order_fee / 100);
        setTotalPrice(data?.course_price - data?.course_price * order_setting?.order_fee / 100);

    }, [prams.id, select_course, order_setting]);

    const setSelectedFile = (e) => {
        setFile(e.target.files[0])
        setPhotoURL(URL.createObjectURL(e.target.files[0]))
    };

    const onchangeCover = (e) => {
        if (e.target.files[0]) {
            setVideoFile(e.target.files[0])
            setVideoURL(URL.createObjectURL(e.target.files[0]))
        }

        // console.log(e.target.files[0]);
    };

    const handleSubmit = async(event) => {
        event.preventDefault();
        setLoad(true);
        const editorData = editorRef.current?.editor?.getData();
        const editorData1 = editorRef1.current?.editor?.getData();
        if (event.target.title.value == "") {
            toast.error('Course name was required')
            setStep(1)
            return false;
        }

        const fd = new FormData();
        fd.append('title', event.target.title.value)
        fd.append('description', editorData)
        fd.append('requirements', editorData1)
        fd.append('category', event.target.category.value)
        fd.append('price', event.target.price.value)
        fd.append('status', event.target.status.value)
        fd.append('gender_id', event.target.gender.value)
        fd.append('course_type', event.target.course_type.value)
        fd.append('_method', 'PUT')
        // if (file) {
        //     fd.append('image', file, file?.name)
        // }

        if (cropper) {
            const croppedBlob = await new Promise((resolve) => {
                cropper.getCroppedCanvas().toBlob((blob) => {
                    resolve(blob);
                }, "image/jpeg");
            });

            if (croppedBlob) {
                fd.append('image', croppedBlob, 'thumbnail.jpg');
            }
        }

        if (video_file) {
            fd.append('video_attach', video_file, video_file?.name)
        }

        API.post("/v1/oauth/teacher/course/" + data?.id + "/update", fd, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: progressEvent => {
                console.log("Progress: " + progressEvent.loaded / progressEvent.total);

            }
        }).then((response) => {
            setLoad(false)
            document.getElementById("msform").reset()
            toast.success(response.data.message);
            dispatch(EDIT_MY_COURSE_SUCCESS(response.data.data))
            navigate('/dashboard/my-courses')
        }).catch((err) => {
            toast.error(err.response.data.message);
            setLoad(false)
        });
    }

    useEffect(() => {
        if (data?.course_type === 'recorded') {
            setCourseType(1);
        } else if (data?.course_type === 'group') {
            setCourseType(2);
        } else if (data?.course_type === '1-1') {
            setCourseType(3);
        }
    }, [data]);

    if (data === undefined) {
        return <h1 className="text-center mt-10">Course not found</h1>;
    }

    const calculateTotalPrice = (price) => {
        setTotalPrice(Number(price) - Number(price * order_setting?.order_fee) / 100);
        setTotalFee(Number(price) * Number(order_setting?.order_fee) / 100);

    }


    return (
        <>
            <div className="tutor-sign-in-section newCourseUpload">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <form id="msform" onSubmit={handleSubmit}>
                                <ul id="progressbar" className="newCourseUploadprogressbar">
                                    <li className={`${step == 0 ? 'active' : ''}`} id="type">Course Type</li>
                                    <li className={`${step == 1 ? 'active' : ''}`} id="info">Basic Info</li>
                                    <li className={`${step == 2 ? 'active' : ''}`} id="media">Media</li>
                                    {course_type == 1 ?
                                        <li className={`${step == 3 ? 'active' : ''}`} id="prices">Price</li>
                                        : ''}

                                </ul>

                                <div className="row justify-content-center">
                                    <div className="col-md-12">
                                        <fieldset className={`text-end ${step == 0 ? 'active show' : 'hide'}`} >
                                            <div className="form-card card mb-4">
                                                <div className="card-body">
                                                    <div className="step-heading newCourseUploadStepHeading">
                                                        <h2>First, let's find out what type of course you're making.</h2>
                                                    </div>
                                                    <div className="select-course-type">
                                                        <input
                                                            name="course_type"
                                                            type="hidden"
                                                            value={course_type}
                                                            readOnly
                                                        />
                                                        <div className="row">
                                                            <div className="col-xl-4 col-lg-4 col-md-4">
                                                                <div className={`categoryCard ${course_type === 1 ? 'active' : ''}`} onClick={() => courseType(1)}>
                                                                    <div className="categoryCard__icon">
                                                                        <i className="fa-light fa-clapperboard-play" />
                                                                    </div>
                                                                    <div className="categoryCard__content">
                                                                        <h4 className="categoryCard__title">Course</h4>
                                                                        <div className="categoryCard__text">Create rich learning experiences with the help of video lectures, quizzes, coding exercises, etc.</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-4 col-lg-4 col-md-4">
                                                                <div className={`categoryCard ${course_type === 2 ? 'active' : ''}`} onClick={() => courseType(2)}>
                                                                    <div className="categoryCard__icon">
                                                                        <i className="fa-light fa-users-class" />
                                                                    </div>
                                                                    <div className="categoryCard__content">
                                                                        <h4 className="categoryCard__title">Group Class</h4>
                                                                        <div className="categoryCard__text">Create rich learning experiences with the help of video lectures, quizzes, coding exercises, etc.</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-4 col-lg-4 col-md-4">
                                                                <div className={`categoryCard ${course_type === 3 ? 'active' : ''}`} onClick={() => courseType(3)}>
                                                                    <div className="categoryCard__icon">
                                                                        <i className="fa-sharp fa-light fa-list-check" />
                                                                    </div>
                                                                    <div className="categoryCard__content">
                                                                        <h4 className="categoryCard__title">Live Class</h4>
                                                                        <div className="categoryCard__text ">Help students prepare for certification exams by providing practice questions.</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <button onClick={() => setStep(step + 1)} type="button" className="next action-button input-hight-44 btn btn-login box-shadow-1 fw-bold">Next</button>
                                        </fieldset>
                                        <fieldset className={`text-end ${step == 1 ? 'active show' : 'hide'}`}>
                                            <div className="form-card card mb-4">
                                                <div className="card-body">
                                                    <div className="step-heading newCourseUploadStepHeading">
                                                        <h2>Basic Information</h2>
                                                    </div>
                                                    <div className="step-form">
                                                        <div className="form-group mb-3 row">
                                                            <div className="col-12">
                                                                <label htmlFor="Title" className="form-label ">Course Title <span className="ms-1 text-danger">*</span></label>
                                                                <input name="title" defaultValue={data?.title} type="text" className="form-control rounded input-hight-44" id="Title" placeholder="Learn Figma - UI/UX Design Essential Training" />
                                                            </div>
                                                        </div>

                                                        <div className="form-group mb-3 row">
                                                            <div className="col-6">
                                                                <label className="form-label ">For Gender <span className="ms-1 text-danger">*</span> </label>
                                                                <select name="gender" required className="form-select input-hight-44" aria-label="Default select">
                                                                    <option value={3} selected={data?.gender_id == 3 ? true : false}>Both</option>
                                                                    <option value={1} selected={data?.gender_id == 1 ? true : false}>Male</option>
                                                                    <option value={2} selected={data?.gender_id == 2 ? true : false}>Female</option>

                                                                </select>
                                                            </div>

                                                        </div>

                                                        <div className="form-group mb-3 row">
                                                            <div className="col-6">
                                                                <label className="form-label ">Course Category <span className="ms-1 text-danger">*</span> </label>
                                                                <select name="category" className="form-select input-hight-44" aria-label="Default select">
                                                                    <option value={``}>Choose...</option>
                                                                    {categories?.data?.length > 0 && categories?.data?.map((item, i) => (

                                                                        <option value={item.id} selected={data?.category_id == item.id ? true : false}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>

                                                        </div>
                                                        <div className={`form-group mb-3 row ${data?.active == 1 ? 'd-block' : 'd-none'}`}>
                                                            <div className="col-6">
                                                                <label className="form-label ">Course Status <span className="ms-1 text-danger">*</span> </label>
                                                                <select name="status" className="form-select input-hight-44" aria-label="Default select">
                                                                    <option value={0}>Choose...</option>

                                                                    <option value={1} selected={data?.active == 1 ? true : false}>Published</option>
                                                                    <option value={0} selected={data?.active == 0 ? true : false}>Unpublished</option>

                                                                </select>
                                                            </div>

                                                        </div>

                                                        <div className="form-group mb-3 row">
                                                            <div className="col-12">
                                                                <label className="form-label ">Course Description <span className="ms-1 text-danger">*</span></label>

                                                                <CKEditor name="description"
                                                                    editor={ClassicEditor}
                                                                    data={data?.description}
                                                                    onReady={(editor) => {
                                                                        editorRef.current = { editor }; // Store the editor instance in ref
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="form-group mb-3 row">
                                                            <div className="col-12">
                                                                <label className="form-label ">Course Requirements <span className="ms-1 text-danger">*</span></label>
                                                                <CKEditor name="requirements"
                                                                    editor={ClassicEditor}
                                                                    data={data?.requirements ?? ''}
                                                                    onReady={(editor) => {
                                                                        editorRef1.current = { editor };
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <button onClick={() => setStep(step - 1)} type="button" className="next action-button input-hight-44 btn btn-secondary box-shadow-1 fw-bold me-1">Back</button>
                                            <button onClick={() => setStep(step + 1)} type="button" className="next action-button input-hight-44 btn btn-login box-shadow-1 fw-bold">Next</button>
                                        </fieldset>
                                        <fieldset className={`text-end ${step == 2 ? 'active show' : 'hide'}`}>
                                            <div className="form-card card mb-4">
                                                <div className="card-body">
                                                    <div className="step-heading newCourseUploadStepHeading">
                                                        <h2>Upload Media</h2>
                                                    </div>
                                                    <div className="step-form">
                                                        <div className="form-group mb-3 row">

                                                            <div className="col-12">
                                                                <label className="form-label ">Course Thumbnail <span className="ms-1 text-danger">*</span></label>
                                                                <input
                                                                    className="form-control"
                                                                    type="file"
                                                                    onChange={handleFileChange}
                                                                    accept="image/jpeg, image/png"
                                                                />


                                                                {(selectedFile) && (
                                                                    <Cropper
                                                                        className="mt-3"
                                                                        style={{ height: 300, width: "50%" }}
                                                                        aspectRatio={16 / 9}
                                                                        src={selectedFile}
                                                                        viewMode={1}
                                                                        guides={true}
                                                                        background={false}
                                                                        onInitialized={(instance) => setCropper(instance)}
                                                                    />
                                                                )}
                                                            </div>

                                                            <div className="col-4 mt-2">
                                                                <div
                                                                    style={{
                                                                        backgroundImage: `url(${photoURL ?? data?.cover_image})`,
                                                                        backgroundSize: "cover", // Ensures the image covers the entire container
                                                                        backgroundPosition: "center", // Centers the image
                                                                        backgroundRepeat: "no-repeat", // Prevents tiling
                                                                        height: "200px", // Adjust the height as needed
                                                                        width: "100%", // Full width of the container
                                                                    }}
                                                                    className="photos-upload-inner newCourseUploadStepPhotos position-relative border rounded"
                                                                    id="cvpotos1"
                                                                >
                                                                   
                                                                </div>
                                                            </div>
                                                            <div className="col-12 mt-3">
                                                                <label htmlFor="cover_video" className="form-label ">Cover Video</label>
                                                                <input className="form-control" type="file" onChange={(e) => onchangeCover(e)} accept="video/*" />
                                                            </div>
                                                            <div className="col-6 mt-4 d-none">
                                                                <video key={0}
                                                                    controls={true}
                                                                    preload="metadata"
                                                                    type="video/mp4"
                                                                    height="200"
                                                                    poster={`${photoURL ?? data?.cover_image}`}
                                                                    oncontextmenu="return false;"
                                                                    hideControls={['playbackrates']}
                                                                    src={`${videoURL ?? data?.cover_video}`}
                                                                    className="w-100 video-hover-play"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <button onClick={() => setStep(step - 1)} type="button" className="next action-button input-hight-44 btn btn-secondary box-shadow-1 fw-bold me-2">Back</button>
                                            {course_type == 1 ?
                                                <button onClick={() => setStep(step + 1)} type="button" className="next action-button input-hight-44 btn btn-login box-shadow-1 fw-bold">Next</button>
                                                :
                                                <button type="submit" className="next action-button input-hight-44 btn btn-login box-shadow-1 fw-bold">Finish{loading ? (
                                                    <span
                                                        className="spinner-border spinner-border-sm ml-5"
                                                        role="status"
                                                        aria-hidden="true"
                                                    ></span>
                                                ) : (
                                                    <span></span>
                                                )}</button>

                                            }
                                        </fieldset>
                                        <fieldset className={`text-end ${step == 3 && course_type == 1 ? 'active show' : 'hide'}`}>
                                            <div className="form-card card mb-4">
                                                <div className="card-body">
                                                    <div className="step-heading newCourseUploadStepHeading">
                                                        <h2>Course Price</h2>
                                                    </div>
                                                    <div className="step-form">
                                                        <div className="form-group mb-3 row">
                                                            <div className="col-6">
                                                                <label htmlFor="Price" className="form-label ">Regular Price($)<span className="ms-1 text-danger">*</span></label>
                                                                <input name="price" onChange={(e) => calculateTotalPrice(e.target.value)} defaultValue={data?.course_price} type="text" className="form-control rounded input-hight-44" id="Price" placeholder="Regular Price" />
                                                            </div>

                                                        </div>

                                                        <div className="form-group mb-3 row">
                                                            <div className="col-6">
                                                                <div className="card">
                                                                    <div className="card-body">
                                                                        <h5 className="card-title">Price Summary</h5>

                                                                        <div className="d-flex justify-content-between">
                                                                            <p className="card-text">Author fee</p>
                                                                            <p className="card-text">${total_fee}</p>
                                                                        </div>
                                                                        <div className="d-flex justify-content-between">
                                                                            <p className="card-text">You'll earn</p>
                                                                            <p className="card-text">${total_price}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <button onClick={() => setStep(step - 1)} type="button" className="next action-button input-hight-44 btn btn-secondary box-shadow-1 fw-bold me-2">Back</button>
                                            <button type="submit" className="next action-button input-hight-44 btn btn-login box-shadow-1 fw-bold">Finish{loading ? (
                                                <span
                                                    className="spinner-border spinner-border-sm ml-5"
                                                    role="status"
                                                    aria-hidden="true"
                                                ></span>
                                            ) : (
                                                <span></span>
                                            )}</button>
                                        </fieldset>
                                    </div>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}