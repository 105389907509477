import { useEffect, useState } from "react";
import API from "../../Network/API";
import PostLoader from '../../assets/loader.gif';
export default function FavCourse(){

    const [courses, setCourse] = useState([]);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        get_course()
      }, []);

      const get_course=()=>{
        setLoading(true)
        API.get(`/v1/oauth/student/favourite-courses`).then((response) => {
            setLoading(false)
            setCourse(response.data.data.courses)
          }).catch((error) => {
            setLoading(false)
          });

      }

const delete_course=(id)=>{
    const confirmed = window.confirm("Are you sure you want to delete this course from your favorites?");
    if(confirmed){
        API.delete(`/v1/oauth/student/delete-favourite-courses/${id}`).then((response) => {
            get_course();
        }).catch((error) => {
        });
    }
  
}

    return (
        <>
         {isLoading ?
        <div className='content' style={{ margin: 'auto', padding: 'auto', textAlign: 'center', height: '500px' }}>
          <img src={PostLoader} alt='Post loader' height={80} width={80} />
        </div>
        :
        <>
        <div className="dashboard-title">
          <h4>Saved Course</h4>
        </div>
        <div className="row">
            {courses?.length<=0?
        <>
        <div className="col-md-12 text-center">
                <h4 style={{fontSize:'18px',color:'#ccc'}}>Empty saved course</h4>
        </div>
        </>  :''  
        }

        {courses?.length > 0 && courses?.map((fav_course, j) => (
          <div className="col-lg-12 d-flex">
            <div className="instructor-list flex-fill shadow-sm">
              <div className="instructor-img">
                <a href="#">
                  <img className="img-fluid"  
             
                  src={fav_course?.course?.cover_image || '/assets/img/no_image.jpg'}
                            alt={fav_course?.course?.title || 'Default Title'}
                            onError={(e) => {
                              e.target.onerror = null; // Prevent infinite loop if default image also fails
                              e.target.src = '/assets/img/no_image.jpg';
                            }}
                  />
                </a>
              </div>
              <div className="instructor-content">
                <div className="course-name">
                  <span className="course-badge">{fav_course?.course?.title}</span>
                </div>
                <h5><a href="#">{fav_course?.course?.teacher?.name}</a></h5>
                <h6>Instructor</h6>
                <div className="instructor-info">
                  <div className="rating-img d-flex align-items-center">
                    <i className="fa-light me-1 fa-book" />
                    <p>{fav_course?.course?.lessons_count}+ Lesson</p>
                  </div>
                  <div className="course-view d-flex align-items-center ms-0 d-none">
                    <i className="fa-light me-1 fa-clock" />
                    <p>0hr 30min</p>
                  </div>
                  <div className="rating-img d-flex align-items-center">
                    <i className="fa-light me-1 fa-users" />
                    <p>{fav_course?.course?.total_enrollment??0} Students</p>
                  </div>
                  <div className="course-rating d-flex align-items-center">
                    <div className="course-rating-icon me-2">
                    <i className={`fas fa-star ${fav_course?.course?.avg_rating >= 0 && fav_course?.course?.avg_rating <= 5 ? 'text-warning' : 'text-dark'}`} />
                            <i className={`fas fa-star ${fav_course?.course?.avg_rating >= 2 && fav_course?.course?.avg_rating <= 5 ? 'text-warning' : 'text-dark'}`} />
                            <i className={`fas fa-star ${fav_course?.course?.avg_rating >= 3 && fav_course?.course?.avg_rating <= 5 ? 'text-warning' : 'text-dark'}`} />
                            <i className={`fas fa-star ${fav_course?.course?.avg_rating >= 4 && fav_course?.course?.avg_rating <= 5 ? 'text-warning' : 'text-dark'}`} />
                            <i className={`fas fa-star ${fav_course?.course?.avg_rating >= 5 && fav_course?.course?.avg_rating <= 5 ? 'text-warning' : 'text-dark'}`} />
                    </div>
                    <span className="course-rating-number">{fav_course?.course?.avg_rating}</span>
                  </div>
                  <a href="#" onClick={()=>delete_course(fav_course?.id)} className="rating-count"><i className="fa-solid fa-heart" /></a>
                </div>
              </div>
            </div>
          </div>
        ))}
        </div>
        </>
}
        </>
    )
}