import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment-timezone';
import toast from "react-hot-toast";

import { USER_LOGGED_IN } from "../../../Reducer/authSlice";
import StudentSettingNav from "./StudentSettingNav";
import API from "../../../Network/API";
export default function ProfileSetting() {
    const { user, accessToken } = useSelector((state) => state.authReducer);
    const select_language = useSelector((state) => state.languageReducer);
    const select_country = useSelector((state) => state.countryReducer);
    const [timezones, setTimezones] = useState([]);
    const [country_id, setCountryId] = useState(0);
    const [selectedTimeZone, setTimeZoneId] = useState();
    const [photoURL, setPhotoURL] = useState(user?.avatar);
    const [file, setFile] = useState(null);
    const [loading, setLoad] = useState(false);
    const dispatch = useDispatch();
    const onChangeTimeZone = (e) => {

        setTimeZoneId(e?.value != null ? e?.value : '')
    };

    useEffect(() => {
        const tzNames = moment.tz.names();
        const newOptions = tzNames.map(tz => ({
            value: tz, // Assuming you want the ID as a string
            label: tz
        }));
        setTimezones(newOptions);

    }, []);

    const setSelectedFile = (e) => {
        setFile(e.target.files[0])
        setPhotoURL(URL.createObjectURL(e.target.files[0]))
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoad(true);

        if (event.target.name.value == "") {
            toast.error('Name name was required')
            return false;
        }

        const fd = new FormData();
        fd.append('name', user?.name)
        fd.append('gender_id', user?.gender_id)
        fd.append('country', event.target.country.value)
        fd.append('phone', event.target.phone.value)
        fd.append('timezone', event.target.timezone.value)

        if (file) {
            fd.append('image', file, file?.name)
        }

        API.post("/v1/oauth/student/profile-update", fd, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: progressEvent => {
                console.log("Progress: " + progressEvent.loaded / progressEvent.total);
            }
        }).then((response) => {
            setLoad(false)
            toast.success('Profile update successfully');
            localStorage.setItem(
                'xller_auth',
                JSON.stringify({
                    user: response?.data?.data?.user,
                    accessToken: localStorage.getItem('xller_token'),
                })
            )

            const userInfo = JSON.parse(localStorage.getItem("xller_auth"));
            if (userInfo) {
                dispatch(USER_LOGGED_IN(userInfo));
            }

        }).catch((err) => {
            toast.error(err.response.data.message);
        });
    }
    return (<>

        <div className="settings-widget card-details shadow-sm">
            <div className="settings-menu p-0">
                <div className="profile-heading">
                    <h3>Settings</h3>
                    <p>You have full control to manage your own account settings</p>
                </div>
                <div className="settings-page-head">
                    <StudentSettingNav active_menu={`profile`}/>
                </div>
                <form  onSubmit={handleSubmit} >
                    <div className="course-group profile-upload-group mb-0 d-flex">
                        <div className="course-group-img profile-edit-field d-flex align-items-center">
                            <a href="#" className="profile-pic"><img src={photoURL} alt={user?.name} className="img-fluid" /></a>
                            <div className="profile-upload-head">
                                <h4><a href="#">Your avatar</a></h4>
                                <p>PNG or JPG no bigger than 800px width and height</p>
                                <div className="new-employee-field">
                                    <div className="d-flex align-items-center mt-2">
                                        <div className="image-upload mb-0">
                                            <input type="file" onChange={(e) => setSelectedFile(e)}/>
                                            <div className="image-uploads">
                                                <i className="fa-light fa-cloud-upload" />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="checkout-form settings-wrap">
                        <div className="edit-profile-info">
                            <h5>Personal Details</h5>
                            <p>Edit your personal information</p>
                        </div>
                        <div className="form-group mb-3 row">
                            <div className="col-6">
                                <label htmlFor="First_name" className="form-label ">Name</label>
                                <input disabled required name="name" defaultValue={user?.name} type="text" className="form-control rounded input-hight-44" id="First_name" placeholder="Your First name" />
                            </div>
                            <div className="col-6">
                                <label htmlFor="Last_name" className="form-label ">Gender</label>
                                <select disabled className="form-select input-hight-44" name="gender_id" required>
                                    <option value={``}>Select gender</option>
                                    <option selected={user?.gender_id == 1 ? true : false} value={1}>Male</option>
                                    <option selected={user?.gender_id == 2 ? true : false} value={2}>Female</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-group mb-3 row">
                            <div className="col-6">
                                <label  className="form-label ">Country </label>
                                <select required name="country" className="form-select input-hight-44">
                                    {!select_country?.loading && select_country?.data?.map(country => (
                                        <option selected={country.code == user?.teacher_info?.country ? true : false} value={country.code}>{country.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-6">
                                <label className="form-label ">Timezone </label>

                                <select required name="timezone" className="form-select input-hight-44">
                                    {timezones?.map(timezone => (
                                        <option selected={timezone.label == user?.teacher_info?.timezone ? true : false} value={timezone.value}>{timezone.label}</option>
                                    ))}
                                </select>

                            </div>
                        </div>
                        <div className="form-group mb-3 row">
                            <div className="col-6">
                                <label className="form-label ">Phone number (optional)</label>
                                <div className="input-group">
                                    <input name="phone" defaultValue={user?.teacher_info?.phone} type="text" className="form-control input-hight-44  fc-phone" />
                                </div>
                            </div>
                            
                         
                        </div>
                       

                        

                        <div className="mb-3 d-flex justify-content-end ">
                            <button type="submit" className="input-hight-44 btn btn-login box-shadow-1 fw-bold">Update {loading ? (
                                                    <span
                                                      className="spinner-border spinner-border-sm ml-5"
                                                      role="status"
                                                      aria-hidden="true"
                                                    ></span>
                                                  ) : (
                                                    <span></span>
                                                  )}</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

    </>)
}