import { useDispatch, useSelector } from "react-redux";
import { TEACHER_ENROLLMENT_LIVE_FETCH_START, TEACHER_ENROLLMENT_LIVE_FETCH_SUCCESS } from "../../Reducer/tutorEnrollmentLiveSlice";
import { TEACHER_ENROLLMENT_FETCH_START, TEACHER_ENROLLMENT_FETCH_SUCCESS } from "../../Reducer/tutorEnrollmentSlice";
import API from "../../Network/API";
import { useEffect, useState } from "react";
import CourseTableRowStudent from "../../Props/CourseTableRowStudent";

export default function MyLesson(){
   const enrollment_course_recorded = useSelector((state) => state.teacherEnrollmentReducer);
       const enrollment_course = useSelector((state) => state.teacherEnrollmentLiveReducer);
       const [loading, setLoad] = useState(false);
       const [loading_course, setLoadCourse] = useState(false);
       const dispatch = useDispatch();
       useEffect(() => {
        setLoad(true)
        setLoadCourse(true)
           TEACHER_ENROLLMENT_LIVE_FETCH_START();
           API.get(`/v1/oauth/student/my_enrollment_course?type=live`).then((response) => {
            setLoad(false)
               dispatch(TEACHER_ENROLLMENT_LIVE_FETCH_SUCCESS(response.data.data))
           }).catch((error) => {
            setLoad(false)
           });
   
           TEACHER_ENROLLMENT_FETCH_START();
           API.get(`/v1/oauth/student/my_enrollment_course?type=recorded`).then((response) => {
            setLoadCourse(false)
               dispatch(TEACHER_ENROLLMENT_FETCH_SUCCESS(response.data.data))
           }).catch((error) => {
            setLoadCourse(false)
           });
   
       }, []);

 return (
   <>

   <div className="dashboard-title">
       <h4>All Enrollment</h4>
   </div>
   <div className="row">
       <div className="col-12">

           <ul class="nav nav-tabs">
               <li className="nav-item">
                   <a className="nav-link active" data-bs-toggle="tab" href="#home">Lessons</a>
               </li>
               <li className="nav-item">
                   <a className="nav-link" data-bs-toggle="tab" href="#menu1">Courses</a>
               </li>

           </ul>

           <div className="tab-content">
               <div className="tab-pane container active" id="home">
                   <div className="table-responsive custom-table  mt-5">
                       <table className="table table-nowrap mb-0">
                           <thead>
                               <tr>
                                   
                                   <th>Lesson Name</th>
                                   <th>Course Title</th>
                                   <th>Teacher</th>
                                   <th>Start Time</th>
                                   <th>Remaining</th>
                                
                                   <th>Action</th>
                               </tr>
                           </thead>
                           <tbody>

                           {
                                    loading ?
                                        <tr>
                                            <td colSpan="6" className="text-center">Loading...</td>
                                        </tr>
                                        : ''
                                }



                           {!enrollment_course?.loading && enrollment_course?.courses?.length > 0 && enrollment_course?.courses?.map((course, j) => (
                                           <CourseTableRowStudent data={course} key={j} type={`live`}/>
                                       ))}

                           </tbody>
                       </table>
                   </div>

               </div>
               <div className="tab-pane container fade" id="menu1">
               <div className="table-responsive custom-table  mt-5">
                       <table className="table table-nowrap mb-0 text-center">
                           <thead>
                               <tr>
                                   {/* <th>Type</th> */}
                                   <th>Course Name</th>
                                   <th>Total Lesson</th>
                                   <th>Teacher</th>
                                 
                                   <th>Action</th>
                               </tr>
                           </thead>
                           <tbody>

                           {
                                    loading_course ?
                                        <tr>
                                            <td colSpan="4" className="text-center">Loading...</td>
                                        </tr>
                                        : ''
                                }

<>
                                       {!enrollment_course_recorded?.loading && enrollment_course_recorded?.courses?.length > 0 && enrollment_course_recorded?.courses?.map((course, i) => (
                                            <CourseTableRowStudent data={course} key={i} type={`recorded`} />
                                       ))}
                                   </>
                           </tbody>
                       </table>
                   </div>

               </div>
           </div>



       </div>
   </div>
</>
 )
}