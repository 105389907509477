
import { useDispatch, useSelector } from "react-redux";
import CourseTableRow from "../../Props/CourseTableRow";
import API from "../../Network/API";
import { TEACHER_ENROLLMENT_FETCH_START, TEACHER_ENROLLMENT_FETCH_SUCCESS } from "../../Reducer/tutorEnrollmentSlice";
import { useEffect } from "react";
import { TEACHER_ENROLLMENT_LIVE_FETCH_START, TEACHER_ENROLLMENT_LIVE_FETCH_SUCCESS } from "../../Reducer/tutorEnrollmentLiveSlice";
export default function AllEnrollmentCourse() {
    const enrollment_course_recorded = useSelector((state) => state.teacherEnrollmentReducer);
    const enrollment_course = useSelector((state) => state.teacherEnrollmentLiveReducer);
    const dispatch = useDispatch();
    useEffect(() => {
        TEACHER_ENROLLMENT_LIVE_FETCH_START();
        API.get(`/v1/oauth/teacher/enrollment_courses?type=live`).then((response) => {
            dispatch(TEACHER_ENROLLMENT_LIVE_FETCH_SUCCESS(response.data.data))
        }).catch((error) => {
        });

        TEACHER_ENROLLMENT_FETCH_START();
        API.get(`/v1/oauth/teacher/enrollment_courses?type=recorded`).then((response) => {
            dispatch(TEACHER_ENROLLMENT_FETCH_SUCCESS(response.data.data))
        }).catch((error) => {
        });

    }, []);

    return (
        <>

            <div className="dashboard-title">
                <h4>Manage Orders</h4>
            </div>
            <div className="row">
                <div className="col-12">

                    <ul class="nav nav-tabs">
                        <li className="nav-item">
                            <a className="nav-link active" data-bs-toggle="tab" href="#home">Live Courses</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" data-bs-toggle="tab" href="#menu1">Recorded Courses</a>
                        </li>

                    </ul>

                    <div className="tab-content">
                        <div className="tab-pane container active" id="home">
                            <div className="table-responsive custom-table  mt-5">
                                <table className="table table-nowrap mb-0">
                                    <thead>
                                        <tr>
                                            <th>Type</th>
                                            <th>Student</th>
                                            <th>Title</th>
                                            <th>Lesson Name</th>
                                            <th>Start Time</th>
                                            <th>Remaining</th>
                                         
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {enrollment_course?.loading ?
                                            <>
                                                <tr>
                                                    <td colSpan={5} className="text-center fw-bold">Loading.....</td>
                                                </tr>
                                            </>
                                            :

                                            <>
                                                {!enrollment_course?.loading && enrollment_course?.courses?.length > 0 && enrollment_course?.courses?.map((course, j) => (
                                                    <CourseTableRow data={course} key={j} />
                                                ))}
                                            </>
                                        }
                                    </tbody>
                                </table>
                            </div>

                        </div>
                        <div className="tab-pane container fade" id="menu1">
                        <div className="table-responsive custom-table  mt-5">
                                <table className="table table-nowrap mb-0">
                                    <thead>
                                        <tr>
                                            <th>Type</th>
                                            <th>Student</th>
                                            <th>Title</th>
                                            <th>Lesson Name</th>
                                            <th>Start Time</th>
                                            <th>Remaining</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {enrollment_course_recorded?.loading ?
                                            <>
                                                <tr>
                                                    <td colSpan={5} className="text-center fw-bold">Loading.....</td>
                                                </tr>
                                            </>
                                            :

                                            <>
                                                {!enrollment_course_recorded?.loading && enrollment_course_recorded?.courses?.length > 0 && enrollment_course_recorded?.courses?.map((course, i) => (
                                                    <CourseTableRow data={course} key={i} />
                                                ))}
                                            </>
                                        }
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>



                </div>
            </div>
        </>
    )
}