import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import API from "../../Network/API";

export default function StudentTransaction() {
    const dispatch = useDispatch();
    const [data, setData] = useState()
    const [loading, setLoad] = useState(false);
    useEffect(() => {
        setLoad(true)
        API.get(`/v1/oauth/student/transactions`)
            .then((response) => {
                setData(response.data.data)
                setLoad(false)
            }).catch((error) => {
                setLoad(false)
            });
    }, []);

    return (
        <>
            <div className="dashboard-title">
                <h4>Transaction History</h4>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="table-responsive custom-table">
                        <table className="table table-nowrap mb-0">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Type</th>
                                    <th>Note</th>
                                    <th>Amount</th>
                                    <th>Fee</th>
                                    <th>Total</th>

                                </tr>
                            </thead>
                            <tbody>

                                {
                                    loading ?
                                        <tr>
                                            <td colSpan="6" className="text-center">Loading...</td>
                                        </tr>
                                        : ''
                                }

                                {data?.courses?.length > 0 && data?.courses?.map((course, j) => (
                                    <tr>
                                        <td>{course.date}</td>
                                        <td>{course.type}</td>
                                        <td>{course.note}</td>
                                        <td>{course.amount}</td>
                                        <td>{course.fee}</td>
                                        <td>{course.total}</td>

                                    </tr>
                                ))}

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}